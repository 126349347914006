import _ from 'lodash'
import Analytics from '@serv/Analytics.js'
import ClinicalMilestoneService from '@serv/ClinicalMilestoneService'
import ListService from '@serv/ListService'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import MilestoneService from '@serv/MilestoneService'
import moment from 'moment'
import PatientJourney from '@model/PatientJourney'
import Storage from '@serv/Storage'
import store from '@src/store/index'
import StringHelper from '@serv/StringHelper'
import { usePatientTaskCount } from '@composables/tasks/usePatientTaskCount'
import User from '@model/User'
import UserService from '@serv/UserService'
import Utils from '@serv/Utils'

// Patient object.
const patientStandardFields = [
    // Fields on Patient that are not on User
    'bmi',
    'consentSlugs',
    'externalId',
    'heightCm',
    'hospitalNumber',
    'hospitalNumberAmbiguous',
    'hospitalNumberType',
    'invitationTime',
    'isCreatedThroughIntegration',
    'isFlyweight',
    'isMock',
    'isNhsValidated',
    'phoneIsMobile',
    'pincode',
    'ownerSlug',
    'primaryPatientJourneyId',
    'referralPatientJourneys',
    'registrationMethod',
    'sex',
    'weightKg'
]

class Patient extends User {
    constructor(object) {
        object.persona = User.Persona.patient
        super(object)
        this.hasFullPatientJourney = false // set to true only when we receive a full PatientJourney response
        this.setPatientFields(object, false)

        /**
         * It was agreed we will eventually move towards model specific ids e.g patientId, clinicianId, etc.
         * This is to avoid confusion and make it easier to identify the type of id being used.
         * For now, we will continue to use personaId as the id for all models but will eventually phase this out
         * in favour of model specific ids.
         * https://myrecovery.slack.com/archives/C07J6SD9L5N/p1732031269811239
         */
        if (!object.personaId && object.patientId) {
            this.personaId = object.patientId
        }

        this.registrationMethod = this.registrationMethod || '1fa'
        this.hospitalNumberType =
            this.hospitalNumberType || store.state.user?.owner?.keyValues?.hospitalNumberTypeDefault

        this.surveyResults = undefined
        this.surveyResultsFromPrm = false
        this.surveyStepResults = undefined
        this.milestones = this.milestones || [] // TODO remove
        this.exerciseRoutineModifiers = this.exerciseRoutineModifiers || {} // map of activitySlug to modifier
        this.exerciseRoutineDraftModifiers = this.exerciseRoutineDraftModifiers || {} // map of activitySlug to draft modifier
        this.exerciseRoutineHistoricalModifiers = this.exerciseRoutineHistoricalModifiers || {} // map of activitySlug to historical modifiers

        this.rtmClinicianId = object.rtmClinicianId
        this.ptClinicianId = object.ptClinicianId
        this.ptVirtualClinicianId = object.ptVirtualClinicianId
        this.careNavigatorClinicianId = object.careNavigatorClinicianId

        this.consentSlugs = this.consentSlugs || []
        this.hasSteps = object.hasSteps
        if (object.keyValues && !object.keyValues.isFlagged) {
            object.keyValues.isFlagged = false
        }
        // PatientJourneys
        if (object.patientJourneys) {
            const patientJourneyObjects = object.patientJourneys
            const patientJourneys = patientJourneyObjects.map(pjObject => {
                pjObject.patientId = object.personaId

                return new PatientJourney(pjObject)
            })
            // This will define globalJourney, firstJourney, primaryJourney (may be undefined), secondaryJourneys, inactiveJourneys
            this.resolvePatientJourneys(patientJourneys)
        } else {
            // MockService.mockPatient() creates the patient with no journeys, then adds them and resolves directly
        }
        this.secondaryJourneys = this.secondaryJourneys || []
        this.inactiveJourneys = this.inactiveJourneys || []
        this.referralPatientJourneys = object.referralPatientJourneys // Existing patient, list of referable patient journeys
    }

    get adminListProperties() {
        let properties = `isRegistered: ${this.isRegistered}`
        const props = ['phone', 'hospitalNumber', 'externalId']
        props.forEach(property => {
            if (this[property]) {
                properties += `, ${property}: ${this[property]}`
            }
        })

        return properties
    }

    // Return true if email is set and in anonymous format.
    get isEmailAnonymous() {
        return UserService.isEmailAnonymous(this.email)
    }

    // Has identified (non-anonymous) email
    get isEmailIdentified() {
        return UserService.isEmailIdentified(this.email)
    }

    get isUsernameEmail() {
        return this.email && this.username == this.email
    }
    get isUsernamePhone() {
        return this.phone && this.username == this.phone
    }

    // Return true only if the patient's hospitalNumber is an NHS number, and has not been validated.
    get isNhsNumberUnvalidated() {
        return this.hospitalNumberType == 'nhs' && this.isNhsValidated === false
    }

    get isRealUsername() {
        return this.email || this.phone
    }

    /**
     * When displaying a hospitalNumber, use this getter instead of the hospitalNumber property.
     * Depending on hospitalNumberType, we may render the string differently.
     */
    get hospitalNumberDisplay() {
        let text
        if (
            this.hospitalNumberType == 'nhs' &&
            typeof this.hospitalNumber == 'string' &&
            this.hospitalNumber.length == 10
        ) {
            const part0 = this.hospitalNumber.slice(0, 3)
            const part1 = this.hospitalNumber.slice(3, 6)
            const part2 = this.hospitalNumber.slice(6, 10)
            text = `${part0} ${part1} ${part2}`
        } else {
            text = this.hospitalNumber
        }
        const owner = store.state.user.owner
        if (
            this.hospitalNumberAmbiguous ||
            (owner && owner.hasFeatureFlag && owner.hasFeatureFlag('hasNhsProms') && this.isNhsNumberUnvalidated)
        ) {
            text += ' ??'
        }

        return text
    }

    // Get a readable version of the externalId:
    // - if it's UUID format, return empty string
    // - if owner uses NHS numbers, string first delimited part, which is our integration externalClientId
    // - else return the whole string value
    get readableExternalId() {
        if (!this.externalId) {
            return ''
        }
        if (StringHelper.isUuid(this.externalId)) {
            return ''
        }
        const owner = store.state.user.owner

        if (owner.keyValues.hospitalNumberTypeDefault == 'nhs') {
            const parts = this.externalId.split('-')

            return parts.length == 2 ? parts[1] : this.externalId
        }

        return this.externalId
    }

    // Has patient consented to global terms?
    get isConsented() {
        return this.consentSlugs ? this.consentSlugs.includes('global') : false
    }

    /**
     * Set a hospitalNumber value from a display value.
     * Depending on hospitalNumberType, the display value may have different formatting.
     */
    static getUnformattedHospitalNumber(formatted, hospitalNumberType) {
        if (hospitalNumberType == 'nhs') {
            return StringHelper.removeNonDigits(formatted)
        }

        return formatted
    }

    /**
     * Set any standard fields on the Patient or User object.
     * Used in constructor, and by TabCpts that want to PATCH a Patient.
     */
    setPatientFields(object, setUserFields = true) {
        if (setUserFields) {
            this.setUserFields(object)
        }

        patientStandardFields.forEach(field => {
            if (object[field] !== undefined) {
                this[field] = object[field]
            }
        })

        if (Storage.get('spoof')) {
            this.hidePatientPii()
        }
    }

    get hasReadOnlyPatientDash() {
        const hasReadOnlyDash = !!store.state.user.owner.keyValues.dash.hasReadOnlyPatientDash
        const storageValue = Storage.get('hasReadOnlyPatientDash')
        const hasIntegrationReadOnlyPatientDash =
            !!store.state.user.owner.keyValues.featureFlags.hasIntegrationReadOnlyPatientDash

        return (
            (hasReadOnlyDash || (hasIntegrationReadOnlyPatientDash && !!this.isCreatedThroughIntegration)) &&
            storageValue !== false
        )
    }

    hidePatientPii() {
        this.firstName = 'Patient'
        this.lastName = `${this.personaId}`
        this.email = `patient-${this.personaId}@test.mr`
        this.phone = '+447777777777'
        this.dob = '1970-01-01'
    }

    /**
     * Resolve PatientJourney references, from a parsed array.
     */
    resolvePatientJourneys(patientJourneys) {
        // Global
        this.globalJourney = patientJourneys.find(patientJourney => patientJourney.isGlobal)
        if (!this.globalJourney) {
            Logging.error(`Could not find Patient.globalJourney for patient ${this.personaId}`)
        }

        // Inactive
        this.inactiveJourneys = patientJourneys.filter(patientJourney => !patientJourney.isActive)
        // Secondary
        const secondaryJourneys = patientJourneys.filter(
            patientJourney => !patientJourney.isPrimary && !patientJourney.isGlobal && patientJourney.isActive
        )
        this.setSecondaryJourneys(secondaryJourneys)
        // Primary
        const primaryJourney = patientJourneys.find(patientJourney => patientJourney.isPrimary)
        this.setPrimaryJourney(primaryJourney)
    }

    /**
     * Get an array of all pathway journeys (i.e. not the global journey)
     */
    get pathwayJourneys() {
        const primaryJourneys = this.primaryJourney ? [this.primaryJourney] : []

        return [...primaryJourneys, ...this.secondaryJourneys]
    }

    /**
     * Get an array of ALL journeys, including the global journey.
     * If passing includeInactive=true, also include inactive journeys.
     */
    allJourneys(includeInactive) {
        const patientJourneys = []
        if (this.globalJourney) {
            patientJourneys.push(this.globalJourney)
        }
        if (this.primaryJourney) {
            patientJourneys.push(this.primaryJourney)
        }
        const inactiveJourneys = includeInactive ? this.inactiveJourneys : []

        return [...patientJourneys, ...this.secondaryJourneys, ...inactiveJourneys]
    }

    // Update the firstJourney to be the primaryJourney, or the first secondaryJourney.
    _updateFirstJourney() {
        this.firstJourney = this.primaryJourney || this.secondaryJourneys.find(pj => pj)
    }

    /**
     * Set the primary journey (or undefined), which also updates the first journey.
     * NOTE: Assumes secondaryJourneys have already been set. You may want to use changePrimaryJourney()
     */
    setPrimaryJourney(patientJourney) {
        this.primaryJourney = patientJourney
        this.primaryPatientJourneyId = patientJourney ? patientJourney.patientJourneyId : undefined

        this._updateFirstJourney()
        if (this.firstJourney == undefined) {
            Logging.error(`Could not resolve Patient.firstJourney for patient ${this.personaId}`)
        }
    }

    /**
     * Set the secondary journeys, sorting them as required.
     * We sort increasing by absolute date offset from patientJourney primaryMilestoneDate to 'now'.
     */
    setSecondaryJourneys(patientJourneys, nowMoment) {
        const futureDate = Utils.dateFarFuture
        nowMoment = nowMoment || new moment()
        this.secondaryJourneys = _.clone(patientJourneys)
        this.secondaryJourneys.sort((a, b) => {
            const dateA = a.primaryMilestoneDate || futureDate
            const diffA = new moment(dateA).diff(nowMoment, 'days')
            const dateB = b.primaryMilestoneDate || futureDate
            const diffB = new moment(dateB).diff(nowMoment, 'days')

            return Math.abs(diffA) - Math.abs(diffB)
        })
    }

    /**
     * Set the primary journey (or undefined), which also updates the first journey.
     * If a different primaryJourney is already set, it becomes secondary.
     * The primary journey is set as isPrimary.
     */
    changePrimaryJourney(patientJourney) {
        if (this.primaryJourney && this.primaryJourney != patientJourney) {
            this.primaryJourney.isPrimary = false
            const secondaryJourneys = [
                this.primaryJourney,
                ...this.secondaryJourneys.filter(pj => pj != patientJourney)
            ]
            this.setSecondaryJourneys(secondaryJourneys)
        }
        patientJourney.isPrimary = true
        this.primaryJourney = patientJourney
        this.primaryPatientJourneyId = patientJourney.patientJourneyId

        if (this.secondaryJourneys.some(pj => pj.patientJourneyId === patientJourney.patientJourneyId)) {
            const secondaryJourneys = this.secondaryJourneys.filter(
                pj => pj.patientJourneyId !== patientJourney.patientJourneyId
            )
            this.setSecondaryJourneys(secondaryJourneys)
        }

        this._updateFirstJourney()
        if (this.firstJourney == undefined) {
            Logging.error(`Patient ${this.personaId} could not resolve firstJourney`)
        }
    }

    /**
     * Add a PatientJourney, respecting isPrimary.
     * Any previous primaryJourney becomes a secondary.
     */
    addPatientJourney(patientJourney) {
        if (
            patientJourney == this.firstJourney ||
            patientJourney == this.globalJourney ||
            this.secondaryJourneys.includes(patientJourney)
        ) {
            Logging.error(`Patient already has PatientJourney ${patientJourney.patientJourneyId}`)

            return
        }
        if (patientJourney.isPrimary) {
            this.changePrimaryJourney(patientJourney)
        } else {
            this.secondaryJourneys.push(patientJourney)
        }
        this.setSecondaryJourneys(this.secondaryJourneys) // update sorting
    }

    /**
     * Set an existing PatientJourney as primary. The model must already exist
     * as the primary journey (in which case nothing to do), or a secondary journey.
     */
    setExistingPatientJourneyAsPrimary(patientJourney) {
        if (patientJourney == this.primaryJourney) {
            return
        }
        if (!this.secondaryJourneys.includes(patientJourney)) {
            Logging.error(
                `setExistingPatientJourneyAsPrimary but not an existing secondary journey: ${patientJourney.patientJourneyId}`
            )

            return
        }
        patientJourney.isPrimary = true
        if (this.primaryJourney) {
            this.primaryJourney.isPrimary = false
            this.secondaryJourneys.push(this.primaryJourney)
        }
        this.primaryJourney = patientJourney
        this.secondaryJourneys = this.secondaryJourneys.filter(pj => pj != patientJourney)
        this.setSecondaryJourneys(this.secondaryJourneys) // update sorting
    }

    /**
     * Remove an existing PatientJourney. The model must already exist
     * as the primary journey or a secondary journey.
     */
    removePatientJourney(patientJourney) {
        if (patientJourney == this.primaryJourney) {
            this.setPrimaryJourney(undefined)

            return
        }
        if (!this.secondaryJourneys.includes(patientJourney)) {
            Logging.error(
                `removePatientJourney but not an existing primary or secondary journey: ${patientJourney.patientJourneyId}`
            )

            return
        }
        this.secondaryJourneys = this.secondaryJourneys.filter(pj => pj != patientJourney)
    }

    /**
     * Get the total number of patient journeys, excluding the global journey.
     */
    get numPatientJourneys() {
        return this.secondaryJourneys.length + (this.primaryJourney ? 1 : 0)
    }

    // True if the patient has multiple active journeys, or any inactive journeys.
    get hasMultipleActiveOrInactiveJourneys() {
        return this.numPatientJourneys + this.inactiveJourneys.length > 1
    }

    /**
     * This getter will eventually be phased out as we agreed to move towards model specific ids e.g patientId
     * being returned from the API instead of personaId.
     * https://myrecovery.slack.com/archives/C07J6SD9L5N/p1732031269811239
     */
    get patientId() {
        return this.personaId
    }

    /**
     * Getters redirected to firstJourney
     */
    get journeySlug() {
        return this.firstJourney.journeySlug
    }
    get patientJourneyId() {
        return this.firstJourney.patientJourneyId
    }
    get side() {
        return this.firstJourney.side
    }
    get teamId() {
        return this.firstJourney.teamId
    }

    get sexString() {
        if (this.sex === 'm' || this.sex === 'f') {
            return Locale.getLanguageItem(this.sex === 'm' ? 'formSexMale' : 'formSexFemale')
        }

        return null
    }

    get initials() {
        let letters = ''
        if (this.firstName) {
            letters += this.firstName[0]
        }
        if (this.lastName) {
            letters += this.lastName[0]
        }

        return letters.toUpperCase()
    }

    /**
     * Return all Milestones (an array) that match the specified slug.
     * Known 'global' slugs are searched on the globalJourney only.
     * Others are searched on the firstJourney only, OR all pathway journeys.
     */
    getMilestonesOfSlug(slug, allPathwayJourneys, includeInactive) {
        if (Milestone.globalSlugs.includes(slug) && this.globalJourney) {
            return this.globalJourney.getMilestonesOfSlug(slug, includeInactive)
        }
        if (allPathwayJourneys) {
            // Aggregate results from all pathwayJourneys
            const milestones = this.pathwayJourneys.reduce(
                (milestones, patientJourney) => [
                    ...milestones,
                    ...patientJourney.getMilestonesOfSlug(slug, includeInactive)
                ],
                []
            )

            return milestones
        }

        return this.firstJourney.getMilestonesOfSlug(slug, includeInactive)
    }

    /**
     * Return the first Milestone that matches the specified slug, or undefined if none.
     * If there are multiple, we can also specify 'earliest' (default) or 'latest' - else we return the first.
     */
    getMilestoneOfSlug(slug, qualifier, allPathwayJourneys, includeInactive, subtype) {
        let milestones = this.getMilestonesOfSlug(slug, allPathwayJourneys, includeInactive)
        if (subtype) {
            milestones = milestones.filter(milestone => milestone.subtype == subtype)
        }
        if (milestones.length >= 1) {
            MilestoneService.sortMilestonesByMoment(milestones)
            if (qualifier) {
                if (qualifier == 'earliest') {
                    return milestones[0]
                }

                if (qualifier == 'latest') {
                    return milestones[milestones.length - 1]
                }
            }

            return milestones[0]
        }
    }

    /**
     * Return all Milestones (an array) that match the specified type.
     * Searches across all journeys.
     */
    getMilestonesOfType(type, includeInactive) {
        let milestones = []
        for (const patientJourney of this.allJourneys()) {
            const matching = patientJourney.getMilestonesOfType(type, includeInactive)
            milestones = [...milestones, ...matching]
        }

        return milestones
    }

    /**
     * Return the first Milestone that matches the specified id, or undefined if none.
     * We search across: primaryJourney (if defined), globalJourney, secondaryJourneys.
     */
    getMilestoneOfId(id) {
        for (const patientJourney of this.allJourneys()) {
            const milestone = patientJourney.milestones.find(milestone => milestone.id == id)
            if (milestone) {
                return milestone
            }
        }
    }

    /**
     * Return the date of the single Milestone specified by slug, or undefined if the Milestone
     * could not be found, or the date is undefined. If there are multiple, we return the first and log an error.
     */
    getMilestoneOfSlugDate(slug, includeInactive) {
        const milestone = this.getMilestoneOfSlug(slug, includeInactive)

        return milestone ? milestone.date : undefined
    }

    /**
     * Get the patientJourney that the milestone is on, or undefined if not found.
     */
    getPatientJourneyFromMilestoneId(milestoneId) {
        for (const patientJourney of this.allJourneys()) {
            const milestone = patientJourney.milestones.find(milestone => milestone.id == milestoneId)
            if (milestone) {
                return patientJourney
            }
        }
    }

    /**
     * Set a milestone date (string).
     */
    setMilestoneDate(milestone, date, withoutRebuild) {
        if (milestone.date != date) {
            milestone.setDate(date)
            if (!withoutRebuild) {
                this.rebuildScheduleEvents()
                this.rebuildClinicalMilestones()
                this.rebuildListColumns()
            }
        }
    }

    /**
     * Set multiple milestone dates (from strings).
     */
    setMilestonesDates(milestones, dates, withoutRebuild) {
        if (milestones.length != dates.length) {
            Logging.error(`setMilestonesDates called with unequal array length: ${milestones.length}, ${dates.length}`)

            return
        }
        for (let i = 0; i < milestones.length; i++) {
            if (milestones[i].date != dates[i]) {
                milestones[i].setDate(dates[i])
            }
        }
        if (!withoutRebuild && milestones.length > 0) {
            this.rebuildScheduleEvents()
            this.rebuildClinicalMilestones()
            this.rebuildListColumns()
        }
    }

    /**
     * Set the date of the single Milestone specified by slug. If the Milestone could not be found, we log an error.
     * Returns true only if the date was set.
     */
    setMilestoneOfSlugDate(slug, date, allPathwayJourneys) {
        const milestones = this.getMilestonesOfSlug(slug, allPathwayJourneys)
        if (milestones.length >= 1) {
            if (milestones.length > 1) {
                Logging.error(`setMilestoneOfSlugDate(${slug}) returned ${milestones.length} results`)
            } else {
                milestones[0].setDate(date)
                this.listColumnsAreDirty = true

                return true
            }
        }

        return false
    }

    // Helpers for quick access to common Milestone dates.
    get invitationDate() {
        return this.globalJourney.getMilestoneOfSlugDate('invitation')
    }
    get registrationDate() {
        return this.globalJourney.getMilestoneOfSlugDate('registration')
    }
    get operationDate() {
        return this.getMilestoneOfSlugDate('operation')
    }
    get dischargeDate() {
        return this.getMilestoneOfSlugDate('discharge')
    }

    /**
     * Return a list of all users to whom the patient is currently referred.
     */
    getCurrentReferees() {
        const milestones = this.getMilestonesOfType(Milestone.Type.referral)
        const users = new Set()
        milestones.forEach(milestone => {
            if (!milestone.endDate) {
                const user = store.state.user.users[milestone.referredToId]
                users.add(user)
            }
        })

        return [...users]
    }

    /**
     * Rebuild this.clinicalMilestones.
     * If NOT using PJ list: We only do this if they already exist - otherwise the startup flow will do it anyway.
     */
    rebuildClinicalMilestones() {
        if (this.clinicalMilestones || store.state.user.owner.hasPatientJourneyList) {
            const clinicalMilestones = ClinicalMilestoneService.getClinicalMilestonesResolvedForPatientJourney(
                this,
                this.firstJourney
            )
            store.commit('setPatientClinicalMilestones', {
                patientId: this.patientId,
                data: clinicalMilestones
            })

            const { setComponentTaskCount } = usePatientTaskCount()
            const count = clinicalMilestones.reduce(
                (tasksCount, milestone) => tasksCount + (milestone.isTask || milestone.isDownloadPdfTask ? 1 : 0),
                0
            )
            setComponentTaskCount('TabCptResultsClinicalMilestones', count)
        }
    }

    /**
     * Rebuild and resolve scheduleEvents for all PJs.
     * On any relevant model changes, this should be called BEFORE rebuildListColumns.
     */
    rebuildScheduleEvents(always) {
        const owner = store.state.user.owner
        if (!always && owner.hasPatientJourneyList) {
            return
        }
        if (!always && !owner.requiresPatientJourneyScheduleEvents) {
            return
        }
        const seProps = []
        for (const patientJourney of this.pathwayJourneys) {
            patientJourney.rebuildScheduleEvents(always)
            patientJourney.resolveScheduleEvents()
            for (const se of patientJourney.scheduleEvents) {
                if (se.activitySlug.includes('-surv') && !se.activitySlug.includes('always-rep')) {
                    seProps.push(
                        `patientJourneyId=${patientJourney.patientJourneyId}, seActivitySlug=${se.activitySlug}, seStartDate=${se.startDate}, seSurveyResults=[${se.surveyResults.map(sr => `${sr.uuid} ${sr.endTime} ${sr.status}`).join()}]`
                    )
                }
            }
        }
        // Log summary of ScheduleEvents for clinical-surveys
        Analytics.sendEvent('patientRebuildScheduleEvents', {
            registrationDate: this.registrationDate,
            scheduleEvents: seProps
        })
    }

    /**
     * Rebuild this.listColumns.
     * We only do this if they already exist - otherwise the startup flow will do it anyway.
     */
    rebuildListColumns() {
        const owner = store.state.user.owner
        if (owner.hasPatientJourneyList) {
            return
        }
        if (this.listColumns) {
            const patientListColumns = ListService.getPatientListColumns()
            const listColumns = ListService.getListColumnsResolvedForRow(patientListColumns, this)
            store.commit('setPatientListColumns', {
                patientId: this.patientId,
                data: listColumns
            })
        }
    }

    /**
     * Set the full set of SurveyResult objects for this Patient.
     * Also sets sublists for each PatientJourney.
     */
    setSurveyResults(surveyResults) {
        this.surveyResults = surveyResults
        if (!this.firstJourney || !this.globalJourney) {
            Logging.error(
                `Patient.setSurveyResults being called before PatientJourneys are set, for patient: ${this.personaId}`
            )

            return
        }
        for (const patientJourney of this.allJourneys(true)) {
            if (patientJourney) {
                patientJourney.surveyResults = surveyResults.filter(
                    surveyResult => surveyResult.patientJourneyId == patientJourney.patientJourneyId
                )
            }
        }
    }

    getEarliestSurveyResultDate() {
        // Get the earliest survey result date
        let earliestDate = Utils.dateFarFuture
        for (const patientJourney of this.allJourneys(true)) {
            for (const surveyResult of patientJourney.surveyResults) {
                if (surveyResult.date && surveyResult.date < earliestDate) {
                    earliestDate = surveyResult.date
                }
            }
        }

        return earliestDate
    }
}

Patient.ArchiveReason = {
    gdprRequestedDelete: 'gdprRequestedDelete',
    gdprRequestedRemoveProcessing: 'gdprRequestedRemoveProcessing',
    invitationPeriodExceeded: 'invitationPeriodExceeded',
    patientDeceased: 'patientDeceased',
    procedureCancelled: 'procedureCancelled',
    retentionPeriodExceeded: 'retentionPeriodExceeded',
    testAccountPurged: 'testAccountPurged'
}

export default Patient
